button,
.button,
[type="button"],
[type="submit"] {
  &:not(.button-reset) {
    @apply inline-flex justify-center items-center para-sans no-underline outline-none cursor-pointer px-[2rem] py-[.8rem] md:px-[2.4rem] border border-black rounded-full bg-white text-black hover:bg-black hover:text-white transition-all duration-200;

    &:not(:last-child) {
      @apply mr-[.5em];
    }
  }

  &.button-small {
    @apply pt-[.4rem] pr-[1.6rem] pb-[.6rem] pl-[1.2rem] md:pr-[1.8rem] md:pl-[1.4rem] small-sans;
  }

  &.button-inverted {
    @apply border-white bg-transparent text-white hover:bg-white hover:text-black;
  }
}

.button-reset {
  @apply inline bg-transparent border-none rounded-none leading-normal m-0 p-0 hover:bg-transparent;
}

.pagination {
  @apply no-underline flex items-center justify-center mr-[0.8rem] w-[4rem] h-[4rem] border rounded-full font-national font-normal text-caption-sm;
}
