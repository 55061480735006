input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

input[type="date"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="url"],
select {
  @apply w-full max-w-[384px] min-h-[4rem] px-[.8rem] py-[.2rem] border border-black rounded-none;

  .bg-black & {
    @apply border-white;
  }
}

label {
  @apply para-sans;
}

/* Checkbox and radio button fields */
input[type="radio"] {
  @apply rounded-full inline-flex flex-shrink-0 w-[4rem] h-[4rem] justify-center items-center appearance-none outline-none bg-white border border-solid border-black mr-[.4rem] mb-[.8rem] cursor-pointer;

  &:before {
    @apply content-[''] block opacity-0 w-[2rem] h-[2rem] rounded-full bg-black;
  }

  &:checked:before {
    @apply opacity-100;
  }
}
