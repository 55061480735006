@mixin accessible-focus-state() {
  outline: none;
  background-color: theme('colors.highlight') !important;
  color: theme('colors.black.DEFAULT') !important;
  box-shadow:
    // h-offset v-offset blur spread colour
    0 3px 0 0 theme('colors.black.DEFAULT'),
    0 -3px 0 0 theme('colors.highlight');
  text-decoration: none;

  &:hover  {
    background-color: theme('colors.highlight');
    color: theme('colors.black.DEFAULT') !important;
    text-decoration: none;
  }

  path {
    fill: theme('colors.black.DEFAULT') !important;
  }
}

a,
button,
.button,
.header-nav__panel--trigger {
  &:focus-visible {
    @include accessible-focus-state();

    .underline {
      text-decoration: none !important;
    }
  }

  /* Fallback for browsers without :focus-visible support */
  @supports not selector(:focus-visible) {
    &:focus {
      @include accessible-focus-state();

      .underline {
        text-decoration: none !important;
      }
    }
  }
}

input[type="date"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="url"],
input[type="radio"],
input[type="checkbox"],
select,
textarea,
button,
.button {
  &:focus-visible {
    outline: none;
    box-shadow:
      // h-offset v-offset blur spread colour
      0 0 0 4px theme('colors.highlight'),
      inset 0 0 0 4px theme('colors.black.DEFAULT');
  }

  @supports not selector(:focus-visible) {
    &:focus {
      outline: none;
      box-shadow:
        // h-offset v-offset blur spread colour
        0 0 0 4px theme('colors.highlight'),
        inset 0 0 0 4px theme('colors.black.DEFAULT');
    }
  }
}

.link-with-icon-focus-style {
  &:focus-visible {
    border: 3px solid theme('colors.black.DEFAULT') !important;
    box-shadow: none;
  }

  @supports not selector(:focus-visible) {
    &:focus {
      border: 3px solid theme('colors.black.DEFAULT');
      box-shadow: none;
    }
  }
}

.visually-hidden {
  position: absolute;
  left: -9999em;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation: none !important;
    transition: none !important;
  }
}

.skip-link {
  &:focus-visible {
    @include accessible-focus-state();
    position: fixed;
    top: 1em;
    left: 1em;
    width: auto;
    height: auto;
    z-index: 99;
  }

  @supports not selector(:focus-visible) {
    &:focus {
      @include accessible-focus-state();
      position: fixed;
      top: 1em;
      left: 1em;
      width: auto;
      height: auto;
      z-index: 99;
    }
  }
}
