#filter {
  .tags {
    label {
      background-color: theme('colors.white');;
    }

    input[type="checkbox"]:checked + label {
      color: theme('colors.white');
      background-color: theme('colors.black.DEFAULT');
    }
  }
}
