@use 'sass:math';

.outer-container {
  @apply px-[1.6rem] xs:px-[2.4rem] sm:px-[3.2rem];
}

.inner-container {
  @apply max-w-xl mx-auto px-[2.4rem] xs:px-[3.2rem] sm:px-[4.8rem] lg:px-[6.4rem];
}

.content-group {
  @apply py-[4rem] mb-[1.6rem] sm:py-[10.4rem] xs:mb-[2.4rem] sm:mb-[3.2rem];
}

.accordion + .accordion .border-y-2 {
  @apply border-t-0;
}

iframe {
  max-width: 100%;
}

@import "tailwind/base";
@import "tailwind/components";

@import "utility/accessibility";
// @import "utility/print";

@import "base/typography";
@import "base/buttons";
@import "base/forms";
@import "base/admin-bar";

@import "components/site-header-nav";
@import "components/filter";
@import "components/accordion";

@import "matrix/matrix";
@import "matrix/video";

@import "tailwind/utilities";
