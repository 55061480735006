@tailwind base;

@layer base {
  // links:

  a, .link-underline {
    @apply underline decoration-1 underline-offset-2 hover:decoration-2 group-hover:decoration-2
  }

  .link-no-underline {
    @apply no-underline hover:underline hover:decoration-1 hover:underline-offset-2 group-hover:underline group-hover:decoration-1 group-hover:underline-offset-2;
  }

  .data-link {
    @apply cursor-pointer;
  }

  #tabbed {
    section {
      @apply focus:outline-none;
    }

    a.tab-label {
      @apply bg-yellow-pastel-desaturated heading-xs-regular link-no-underline whitespace-nowrap flex pt-[1rem] sm:pt-[2rem] px-[1.2rem] sm:px-[2.8rem] pb-[.8rem] sm:pb-[1.6rem] border-[1.5px] border-black hover:bg-black hover:text-white hover:no-underline;

      &[aria-selected] {
        @apply bg-white cursor-default hover:bg-white hover:text-black;
      }
    }

    li.tab {
      @apply mr-[.8rem] sm:mr-[1.6rem] last:mr-0;
    }

    .tabs-above {
      @apply flex m-0 -mb-[1.5px];

      .tab-label {
        @apply rounded-t-[0.8rem];

        &[aria-selected] {
          @apply border-b-white;
        }
      }
    }

    .tabs-below {
      @apply flex m-0 -mt-[1.5px];

      .tab-label {
        @apply rounded-b-[0.8rem];

        &[aria-selected] {
          @apply border-t-white;
        }
      }
    }

    &.tabs-0 .tab-0,
    &.tabs-1 .tab-1,
    &.tabs-2 .tab-2,
    &.tabs-3 .tab-3 {
      a.tab-label {
        @apply bg-white cursor-default hover:bg-white hover:text-black;
      }
    }

    &.tabs-0 .tabs-above .tab-0,
    &.tabs-1 .tabs-above .tab-1,
    &.tabs-2 .tabs-above .tab-2,
    &.tabs-3 .tabs-above .tab-3 {
      .tab-label {
        @apply border-b-white;
      }
    }

    &.tabs-0 .tabs-below .tab-0,
    &.tabs-1 .tabs-below .tab-1,
    &.tabs-2 .tabs-below .tab-2,
    &.tabs-3 .tabs-below .tab-3 {
      .tab-label {
        @apply border-t-white;
      }
    }
  }
}
