.accordion {
  &__icon {
    transform: rotate(0);

    .expanded & {
      transform: rotate(180deg);
    }
  }
}

.matrix--accordion + .matrix--accordion,
.matrix--summary-accordion + .matrix--summary-accordion {
  .accordion {
    @apply -mt-10 sm:-mt-10-md;

    > div {
      @apply border-t-0;
    }
  }
}
