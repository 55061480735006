//--------//
// Akhand //
//--------//

@font-face {
  font-family: 'Akhand';
  src:  url('/dist/fonts/Akhand-Extrabold.woff2') format('woff2'),
        url('/dist/fonts/Akhand-Extrabold.woff') format('woff');
  font-display: swap;
  font-style: normal;
  font-weight: 800;
}

//------------------//
// Tiempos Headline //
//------------------//

@font-face {
  font-family: 'Tiempos Headline';
  src: url('/dist/fonts/tiempos-headline-light.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'Tiempos Headline';
  src: url('/dist/fonts/tiempos-headline-regular.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}

//--------------//
// Tiempos Text //
//--------------//

@font-face {
  font-family: 'Tiempos Text';
  src: url('/dist/fonts/tiempos-text-regular.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Tiempos Text';
  src: url('/dist/fonts/tiempos-text-regular-italic.woff2') format('woff2');
  font-display: swap;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'Tiempos Text';
  src: url('/dist/fonts/tiempos-text-semibold.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 600;
}

//------------//
// National 2 //
//------------//
@font-face {
  font-family: 'National 2';
  src: url('/dist/fonts/national-2-regular.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'National 2';
  src: url('/dist/fonts/national-2-regular-italic.woff2') format('woff2');
  font-display: swap;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'National 2';
  src: url('/dist/fonts/national-2-medium.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 500;
}

@layer utilities {
  body {
    font-smooth: never;
  }

  .font-akhand {
    letter-spacing: -0.01em;
  }

  .font-tiempos-headline {
    letter-spacing: 0.02em;
  }

  .font-tiempos-text {
    letter-spacing: 0.01em;

    b, strong {
      font-weight: 600;
    }
  }

  .font-national {
    letter-spacing: 0;

    b, strong {
      font-weight: 500;
    }
  }

  .heading-xxl {
    @apply font-akhand text-heading-xxl font-extrabold sm:text-heading-xxl-sm;
  }

  .heading-xl {
    @apply font-akhand text-heading-xl font-extrabold sm:text-heading-xl-sm;
  }

  .heading-l-serif-regular {
    @apply font-tiempos-headline text-heading-l-serif font-normal sm:text-heading-l-serif-sm;
  }

  .heading-l-serif-light {
    @apply font-tiempos-headline text-heading-l-serif font-light sm:text-heading-l-serif-sm;
  }

  .heading-l-sans {
    @apply font-akhand text-heading-l-sans font-extrabold sm:text-heading-l-sans-sm;
  }

  .heading-m-regular {
    @apply font-tiempos-headline text-heading-m font-normal sm:text-heading-m-sm;
  }

  .heading-m-light, .standfirst {
    @apply font-tiempos-headline text-heading-m font-light sm:text-heading-m-sm;
  }

  .heading-s {
    @apply font-tiempos-headline text-heading-s font-normal sm:text-heading-s-sm;
  }

  .heading-xs-regular {
    @apply font-tiempos-headline text-heading-xs font-normal sm:text-heading-xs-sm;
  }

  .heading-xs-light {
    @apply font-tiempos-headline text-heading-xs font-light sm:text-heading-xs-sm;
  }

  .heading-xs-text {
    @apply font-tiempos-text text-heading-xs font-normal sm:text-heading-xs-sm;
  }

  .heading-xxs-regular {
    @apply font-tiempos-headline text-heading-xxs font-normal sm:text-heading-xxs-sm;
  }

  .accordion-number {
    @apply font-akhand font-extrabold text-accordion-number sm:text-accordion-number-sm;
  }

  .para-serif {
    @apply text-para-serif font-tiempos-text sm:text-para-serif-sm;
  }

  .para-serif-semibold {
    @apply text-para-serif font-tiempos-text font-semibold sm:text-para-serif-sm;
  }

  .small-serif {
    @apply text-small-serif font-tiempos-text sm:text-small-serif-sm;
  }

  .para-sans {
    @apply font-national text-para-sans sm:text-para-sans-sm;
  }

  .caption, .small-sans {
    @apply font-national text-caption sm:text-caption-sm font-normal;
  }

  // TODO - tidy this up
  sup.footnote {
    // @apply font-national text-sup font-bold sm:text-sup-sm;
    // font-feature-settings: 'sups' on;
    @apply font-national font-bold;
    &:before {
      content: "[";
    }

    &:after {
      content: "]";
    }

    a {
      @apply link-no-underline;
    }
  }

  p {
    @apply mb-8 sm:mb-8-md;
  }

  .standfirst p {
    @apply mb-12 sm:mb-12-md;
  }

  ol, ul {
    @apply mb-8 sm:mb-8-md;
  }

  .styled-lists {
    ol {
      @apply [counter-reset:section];

      li {
        @apply [counter-increment:section] relative pl-[2.4rem] mb-3 last:mb-0 sm:pl-[3rem] sm:mb-4-md sm:pb-[0];

        &:before {
          @apply [content:counters(section,'')''] absolute left-0 font-semibold;
        }

        &.border-t {
          @apply pt-3;
        }
      }
    }

    ul li {
      @apply relative pl-[2.4rem] mb-3 last:mb-0 sm:pl-[3rem] sm:mb-4-md;

      &:before {
        @apply content-[''] rounded-full block absolute left-0 w-[.8rem] h-[.8rem] sm:w-[1rem] sm:h-[1rem] bg-black top-[1rem] sm:top-[1.3rem];
      }

      &.border-t {
        @apply pt-3;
  
        &:before {
          @apply mt-3;
        }
      }
    }
  }

  .lc--nbm > *:last-child {
    @apply mb-0
  }

  .fc--ntm > *:first-child {
    @apply mt-0;
  }

}
